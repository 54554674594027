import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Layout from '../../components/layout'
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
import Ratio from 'react-bootstrap/Ratio'
import { GatsbyImage, getImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'

//import incus_sat from "../../../images/incus_sat.png"

function trim_string_to_word(in_string: String, length: number) {
  /**Function to trim in_string to length length without cutting off a word
   * @param in_string: Input string
   * @param length: Length
   * @returns new string no longer than length without cutting off a word
   * 
   */

  //trim the string to the maximum length
  var trimmedString = in_string.substring(0, length);

  //re-trim if we are in the middle of a word
  trimmedString = trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
  return trimmedString;

}

function render_news_item(in_news_items) {
  /*
  Function to render the news items 
  */
  var output: JSX.Element[] = [];
  in_news_items.forEach(member => {
    const image: IGatsbyImageData = getImage(member.image)!;
    const headline_text: String = trim_string_to_word(member.article_begin, 95);
    var output_val =
      <a href={member.link} target="_blank">
        <Card key={member.slug} 
        className="extreme-weather-cards h-100">
        <div className="card-img-top">
          <GatsbyImage
            image={image}
            alt={member.alt_text}
          />
        </div>


        <Card.Body><Card.Title>{member.headline}</Card.Title>From {member.source} -- {headline_text}...</Card.Body>
      </Card></a>
    output.push(
      output_val
    );

  });
  return (
    <Row xs={1} md={4} className="g-4">

      {output}

    </Row>
  );
}


const ExtremeWeatherNews = ({ data }) => {
  return (
    <Layout pageTitle="INCUS | Extreme Weather in the News">
      <h1>Extreme Weather in the News</h1>

      {render_news_item(data.allExtremeWeatherNewsJson.nodes)}




    </Layout >

  )
}
export const query = graphql`
query extreme_weather_news_query {
  allExtremeWeatherNewsJson {
    nodes {
      slug
      id
      alt_text
      article_begin
      source
      link
      headline
      image {
        childImageSharp{
          gatsbyImageData(
            width:400
            placeholder: BLURRED
            aspectRatio: 1.7777777
            quality:80

          )
        }
      }
    }
  }
}`;

export default ExtremeWeatherNews

